import React from 'react';

interface YouTubePIPProps {
  videoId: string;
  onClick: () => void;
  isFocused: boolean;
}

const YouTubePIP: React.FC<YouTubePIPProps> = ({ videoId, onClick, isFocused }) => {
  return (
    <div className={`video-item pip youtube-pip ${isFocused ? 'focused' : ''}`} onClick={onClick}>
      <img src={`https://img.youtube.com/vi/${videoId}/0.jpg`} alt="YouTube thumbnail" />
    </div>
  );
};

export default YouTubePIP;