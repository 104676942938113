import SimplePeer from 'simple-peer/simplepeer.min.js';
import io from 'socket.io-client';

console.log('Initializing socket connection');
export const socket = io('https://join.chnl.net', {
  path: '/socket.io/'
});

socket.on('connect', () => {
  console.log('Socket connected successfully');
});

socket.on('connect_error', (error) => {
  console.error('Socket connection error:', error);
});

socket.on('disconnect', () => {
  console.warn('Socket disconnected');
});

socket.on('reconnect', () => {
  console.log('Socket reconnected');
  // Add logic here if you need to rejoin rooms upon reconnection
});

export const joinRoom = (roomId: string) => {
  console.log(`Attempting to join room: ${roomId}`);
  return new Promise<'host' | 'participant'>((resolve) => {
    socket.emit('join', roomId);
    socket.once('created', () => {
      console.log(`Created room: ${roomId}`);
      resolve('host');
    });
    socket.once('joined', () => {
      console.log(`Joined room: ${roomId}`);
      resolve('participant');
    });
  });
};

export const createPeer = (stream: MediaStream, initiator: boolean, roomId: string, peerId?: string) => {
  console.log(`Creating peer - initiator: ${initiator}, roomId: ${roomId}, peerId: ${peerId}`);
  const peer = new SimplePeer({
    initiator,
    stream,
    trickle: false,
    config: {
      iceServers: [
        { urls: 'stun:stun.l.google.com:19302' },
        { urls: 'stun:stun1.l.google.com:19302' },
        { urls: 'stun:stun2.l.google.com:19302' },
        { urls: 'stun:stun3.l.google.com:19302' },
        { urls: 'stun:stun4.l.google.com:19302' },
        // Add your TURN server configuration here if needed
      ]
    }
  });

  peer.on('signal', (signal: SimplePeer.SignalData) => {
    console.log(`Sending signal to peer ${peerId} in room ${roomId}`);
    socket.emit('signal', { roomId, peerId, signal });
  });

  peer.on('connect', () => {
    console.log(`Peer connection established with ${peerId}`);
  });

  peer.on('error', (err: Error) => {
    console.error(`Peer connection error with ${peerId}:`, err);
  });

  peer.on('close', () => {
    console.log(`Peer connection closed with ${peerId}`);
  });

  return peer;
};

export const getLocalStream = async () => {
  console.log('Attempting to get local stream');
  try {
    const constraints: MediaStreamConstraints = {
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
      },
      video: true,
    };
    
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    console.log('Local stream obtained successfully', stream);
    return stream;
  } catch (err) {
    console.error('Failed to get local stream:', err);
    return null;
  }
};

export const handleNewParticipant = (stream: MediaStream, roomId: string, callback: (peerId: string, peer: SimplePeer.Instance) => void) => {
  socket.on('new-participant', (peerId: string) => {
    console.log(`New participant joined: ${peerId}`);
    const peer = createPeer(stream, true, roomId, peerId);
    callback(peerId, peer);
  });
};

export const handleExistingParticipants = (stream: MediaStream, roomId: string, callback: (peerId: string, peer: SimplePeer.Instance) => void) => {
  socket.on('existing-participants', (participants: string[]) => {
    console.log(`Received existing participants: ${participants}`);
    participants.forEach((peerId) => {
      const peer = createPeer(stream, false, roomId, peerId);
      callback(peerId, peer);
    });
  });
};

// Add these event listeners to debug socket events
socket.onAny((eventName, ...args) => {
  console.log(`Received event: ${eventName}`, args);
});

socket.on('error', (error: Error) => {
  console.error('Socket error:', error);
});

socket.on('share-youtube', ({ videoId }) => {
  console.log(`Received shared YouTube video: ${videoId}`);
});

socket.on('youtube-control', (data) => {
  console.log('Received YouTube control:', data);
});