import React, { useState, useEffect } from 'react';
import { getLocalStream } from '../services/webrtc';

interface SetupSequenceProps {
  onComplete: (stream: MediaStream) => void;
}

const SetupSequence: React.FC<SetupSequenceProps> = ({ onComplete }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeStream = async () => {
      try {
        const stream = await getLocalStream();
        if (stream) {
          onComplete(stream);
        }
      } catch (error) {
        console.error('Error getting local stream:', error);
      } finally {
        setIsLoading(false);
      }
    };

    initializeStream();
  }, [onComplete]);

  if (isLoading) {
    return <div>Requesting camera and microphone access...</div>;
  }

  return null;
};

export default SetupSequence;