import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import { socket } from '../services/webrtc';

interface YouTubePlayerProps {
  videoId: string;
  roomId: string;
  isInitiator: boolean;
}

declare global {
  interface Window {
    YT: {
      Player: any;
      PlayerState: {
        PLAYING: number;
        PAUSED: number;
      };
    };
    onYouTubeIframeAPIReady: () => void;
  }
}

const SYNC_INTERVAL = 3000; // Sync every 3 seconds
const BUFFER_TIME = 1; // 1 second buffer

const YouTubePlayer = forwardRef<any, YouTubePlayerProps>(({ videoId, roomId, isInitiator }, ref) => {
  const playerRef = useRef<any>(null);
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true); // Start muted
  const lastUpdateTimeRef = useRef(0);

  const debugLog = (message: string, ...args: any[]) => {
    console.log(`[YouTubePlayer] ${message}`, ...args);
  };

  const handleYouTubeControl = useCallback((data: { state: number; currentTime: number; from: string }) => {
    debugLog('Received youtube-control', data);
    if (playerRef.current && isReady && data.from !== socket.id) {
      const currentTime = playerRef.current.getCurrentTime();
      const timeDiff = Math.abs(currentTime - data.currentTime);

      debugLog('Time difference', { currentTime, receivedTime: data.currentTime, diff: timeDiff });

      if (timeDiff > BUFFER_TIME) {
        debugLog('Seeking to new time', data.currentTime);
        playerRef.current.seekTo(data.currentTime);
      }

      if (data.state === window.YT.PlayerState.PLAYING && !isPlaying) {
        debugLog('Playing video');
        playerRef.current.playVideo();
        setIsPlaying(true);
      } else if (data.state === window.YT.PlayerState.PAUSED && isPlaying) {
        debugLog('Pausing video');
        playerRef.current.pauseVideo();
        setIsPlaying(false);
      }

      // Unmute after synchronization
      if (isMuted) {
        playerRef.current.unMute();
        setIsMuted(false);
      }
    }
  }, [isReady, isPlaying, isMuted]);

  useImperativeHandle(ref, () => ({
    handlePlay: () => {
      if (playerRef.current && isReady) {
        debugLog('handlePlay called');
        playerRef.current.playVideo();
        emitYouTubeControl(window.YT.PlayerState.PLAYING, playerRef.current.getCurrentTime());
      }
    },
    handlePause: () => {
      if (playerRef.current && isReady) {
        debugLog('handlePause called');
        playerRef.current.pauseVideo();
        emitYouTubeControl(window.YT.PlayerState.PAUSED, playerRef.current.getCurrentTime());
      }
    },
    handleSeek: (time: number) => {
      if (playerRef.current && isReady) {
        debugLog('handleSeek called', time);
        playerRef.current.seekTo(time);
        emitYouTubeControl(playerRef.current.getPlayerState(), time);
      }
    },
    handleYouTubeControl,
    syncPlayback: () => {
      if (playerRef.current && isReady) {
        const currentTime = playerRef.current.getCurrentTime();
        const state = playerRef.current.getPlayerState();
        emitYouTubeControl(state, currentTime);
      }
    },
  }), [isReady, handleYouTubeControl]);

  const initializePlayer = () => {
    debugLog('Initializing YouTube player');
    playerRef.current = new window.YT.Player('youtube-player', {
      height: '100%',
      width: '100%',
      videoId: videoId,
      playerVars: {
        autoplay: 1, // Enable autoplay
        controls: 1,
        origin: window.location.origin,
        mute: 1, // Start muted
      },
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange,
      },
    });
  };

  const onPlayerReady = (event: any) => {
    debugLog('YouTube player ready');
    setIsReady(true);
    if (isInitiator) {
      debugLog('Emitting youtube-ready event');
      socket.emit('youtube-ready', { roomId, videoId });
      event.target.playVideo(); // Start playing for initiator
    } else {
      // Request sync from other peers
      socket.emit('youtube-sync-request', { roomId });
    }
  };

  const onPlayerStateChange = (event: any) => {
    const state = event.data;
    debugLog('Player state changed', state);
    setIsPlaying(state === window.YT.PlayerState.PLAYING);
    emitYouTubeControl(state);

    // Unmute after playback starts
    if (state === window.YT.PlayerState.PLAYING && isMuted) {
      event.target.unMute();
      setIsMuted(false);
    }
  };

  const emitYouTubeControl = (state: number, time?: number) => {
    const currentTime = time ?? playerRef.current?.getCurrentTime() ?? 0;
    debugLog('Emitting youtube-control', { state, currentTime, roomId });
    socket.emit('youtube-control', { roomId, state, currentTime, from: socket.id });
    lastUpdateTimeRef.current = Date.now();
  };

  useEffect(() => {
    socket.on('youtube-control', handleYouTubeControl);
    socket.on('youtube-sync-request', () => {
      if (playerRef.current && isReady) {
        const currentTime = playerRef.current.getCurrentTime();
        const state = playerRef.current.getPlayerState();
        emitYouTubeControl(state, currentTime);
      }
    });

    return () => {
      socket.off('youtube-control', handleYouTubeControl);
      socket.off('youtube-sync-request');
    };
  }, [handleYouTubeControl, isReady, roomId]);

  useEffect(() => {
    const syncInterval = setInterval(() => {
      if (playerRef.current && isReady) {
        const currentTime = playerRef.current.getCurrentTime();
        const state = playerRef.current.getPlayerState();
        debugLog('Sync interval', { currentTime, state });
        emitYouTubeControl(state, currentTime);
      }
    }, SYNC_INTERVAL);

    return () => {
      clearInterval(syncInterval);
    };
  }, [isReady, roomId]);

  useEffect(() => {
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = initializePlayer;
    } else {
      initializePlayer();
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, [videoId]);

  return (
    <div
      id="youtube-player"
      style={{ width: '100%', height: '100%', position: 'relative' }}
    >
      <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }} />
    </div>
  );
});

export default YouTubePlayer;