import React, { useState } from 'react';

interface ChatProps {
  roomId: string;
  messages: string[];
  sendMessage: (message: string) => void;
}

const Chat: React.FC<ChatProps> = ({ roomId, messages, sendMessage }) => {
  const [inputMessage, setInputMessage] = useState('');

  const handleSendMessage = (e: React.FormEvent) => {
    e.preventDefault();
    if (inputMessage) {
      sendMessage(inputMessage);
      setInputMessage('');
    }
  };

  return (
    <div className="chat">
      <div className="messages">
        {messages.map((msg, index) => (
          <div key={index}>{msg}</div>
        ))}
      </div>
      <form onSubmit={handleSendMessage}>
        <input
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Type a message..."
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default Chat;