import React, { useState, useCallback, useRef, useEffect, TouchEvent } from 'react';
import { socket, createPeer, joinRoom, handleNewParticipant, handleExistingParticipants } from '../services/webrtc';
import SimplePeer from 'simple-peer';
import Chat from './Chat';
import { FaComments, FaMicrophone, FaMicrophoneSlash, FaCog, FaShareAlt } from 'react-icons/fa';
import SetupSequence from './SetupSequence';
import DeviceSettings from './DeviceSettings';
import MediaShare from './MediaShare';
import YouTubePlayer from './YouTubePlayer';
import YouTubePIP from './YouTubePIP';

interface PeerData {
  peer: SimplePeer.Instance;
  stream: MediaStream | null;
}

const DEFAULT_ROOM_ID = 'default-room';

const Video: React.FC = () => {
  const [localStream, setLocalStream] = useState<MediaStream | null>(null);
  const [peers, setPeers] = useState<{ [key: string]: PeerData }>({});
  const [showChat, setShowChat] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [isAudioMuted, setIsAudioMuted] = useState<boolean>(false);
  const [isSetupComplete, setIsSetupComplete] = useState<boolean>(false);
  const [isHost, setIsHost] = useState<boolean>(false);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [isLocalVideoReady, setIsLocalVideoReady] = useState<boolean>(false);
  const [focusedPeerId, setFocusedPeerId] = useState<string | null>(null);
  const localVideoRef = useRef<HTMLVideoElement | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const mainVideoRef = useRef<HTMLVideoElement>(null);
  const youtubePlayerRef = useRef<any>(null);

  const peersRef = useRef<{ [key: string]: PeerData }>({});

  const log = (message: string, ...args: any[]) => {
    console.log(`[${new Date().toISOString()}] ${message}`, ...args);
  };

  const addPeer = useCallback((peerId: string, peer: SimplePeer.Instance) => {
    log('Adding peer:', peerId);
    peersRef.current[peerId] = { peer, stream: null };
    setPeers({ ...peersRef.current });

    peer.on('stream', (stream: MediaStream) => {
      log('Received stream from peer:', peerId);
      peersRef.current[peerId].stream = stream;
      setPeers({ ...peersRef.current });
      if (!focusedPeerId) {
        setFocusedPeerId(peerId);
      }
    });

    peer.on('close', () => {
      log('Peer connection closed:', peerId);
      removePeer(peerId);
    });

    peer.on('error', (err: Error) => {
      console.error('Peer connection error:', err);
    });
  }, [focusedPeerId]);

  const removePeer = useCallback((peerId: string) => {
    log('Removing peer:', peerId);
    delete peersRef.current[peerId];
    setPeers({ ...peersRef.current });
    if (focusedPeerId === peerId) {
      const remainingPeers = Object.keys(peersRef.current);
      setFocusedPeerId(remainingPeers.length > 0 ? remainingPeers[0] : null);
    }
  }, [focusedPeerId]);

  const initializeRoom = useCallback(async (stream: MediaStream) => {
    log('Initializing room with stream:', stream);
    log('Stream tracks:', stream.getTracks());
    setLocalStream(stream);

    const audioTrack = stream.getAudioTracks()[0];
    setIsAudioMuted(!audioTrack.enabled);

    const role = await joinRoom(DEFAULT_ROOM_ID);
    setIsHost(role === 'host');
    setIsWaiting(role === 'host');

    handleNewParticipant(stream, DEFAULT_ROOM_ID, (peerId, peer) => {
      addPeer(peerId, peer);
      setIsWaiting(false);
    });

    handleExistingParticipants(stream, DEFAULT_ROOM_ID, (peerId, peer) => {
      addPeer(peerId, peer);
      setIsWaiting(false);
    });

    socket.on('signal', ({ peerId, signal }) => {
      if (peersRef.current[peerId]) {
        peersRef.current[peerId].peer.signal(signal);
      } else if (stream) {
        const newPeer = createPeer(stream, false, DEFAULT_ROOM_ID, peerId);
        addPeer(peerId, newPeer);
        newPeer.signal(signal);
      }
    });

    socket.on('peer-disconnected', (peerId: string) => {
      removePeer(peerId);
    });

    setIsSetupComplete(true);
  }, [addPeer, removePeer]);

  const toggleAudio = useCallback(() => {
    if (localStream) {
      const audioTrack = localStream.getAudioTracks()[0];
      audioTrack.enabled = !audioTrack.enabled;
      setIsAudioMuted(!audioTrack.enabled);
      log(`Audio ${audioTrack.enabled ? 'unmuted' : 'muted'}`);
    }
  }, [localStream]);

  const [chatMessages, setChatMessages] = useState<string[]>([]);

  useEffect(() => {
    socket.on('chat message', (msg: string) => {
      setChatMessages((prevMessages) => [...prevMessages, msg]);
    });

    return () => {
      socket.off('chat message');
    };
  }, []);

  const sendChatMessage = (message: string) => {
    socket.emit('chat message', { roomId: DEFAULT_ROOM_ID, msg: message });
  };

  const [pipPosition, setPipPosition] = useState({ x: 20, y: 20 });
  const pipRef = useRef<HTMLDivElement>(null);
  const isDraggingRef = useRef(false);
  const startPositionRef = useRef({ x: 0, y: 0 });

  const handleTouchStart = (e: TouchEvent) => {
    if (pipRef.current) {
      isDraggingRef.current = true;
      startPositionRef.current = {
        x: e.touches[0].clientX - pipPosition.x,
        y: e.touches[0].clientY - pipPosition.y,
      };
    }
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (isDraggingRef.current && pipRef.current) {
      const newX = e.touches[0].clientX - startPositionRef.current.x;
      const newY = e.touches[0].clientY - startPositionRef.current.y;
      setPipPosition({ x: newX, y: newY });
    }
  };

  const handleTouchEnd = () => {
    isDraggingRef.current = false;
  };

  const [showMediaShare, setShowMediaShare] = useState<boolean>(false);
  const [sharedVideoId, setSharedVideoId] = useState<string | null>(null);
  const [isYouTubeMainVideo, setIsYouTubeMainVideo] = useState<boolean>(false);
  const [isYouTubeInitiator, setIsYouTubeInitiator] = useState<boolean>(false);

  const handleMediaShare = (videoId: string, type: 'youtube' | 'twitter') => {
    if (type === 'youtube') {
      setSharedVideoId(videoId);
      socket.emit('share-youtube', { roomId: DEFAULT_ROOM_ID, videoId });
    }
    setShowMediaShare(false);
  };

  const [showControls, setShowControls] = useState(true);

  const [mainVideoType, setMainVideoType] = useState<'youtube' | 'peer' | null>(null);

  const handleYouTubePIPClick = () => {
    setIsYouTubeMainVideo(true);
    setIsYouTubeInitiator(true);
    setMainVideoType('youtube');
    setFocusedPeerId(null);
    socket.emit('youtube-main-video', { roomId: DEFAULT_ROOM_ID, videoId: sharedVideoId });
    setShowControls(false);
  };

  const handlePipClick = (peerId: string) => {
    if (peerId === focusedPeerId) return; // Do nothing if clicking on the current main video PIP

    if (isYouTubeMainVideo) {
      setIsYouTubeMainVideo(false);
      setShowControls(true);
    }
    setFocusedPeerId(peerId);
    setMainVideoType('peer');
  };

  const debugLog = (message: string, ...args: any[]) => {
    console.log(`[Video] ${message}`, ...args);
  };

  useEffect(() => {
    socket.on('share-youtube', ({ videoId }) => {
      debugLog('Received share-youtube event', videoId);
      setSharedVideoId(videoId);
      setIsYouTubeMainVideo(false);
      setIsYouTubeInitiator(false);
      setMainVideoType(null);
      setShowControls(true);
    });

    socket.on('youtube-main-video', ({ videoId }) => {
      debugLog('Received youtube-main-video event', videoId);
      setSharedVideoId(videoId);
      setIsYouTubeMainVideo(true);
      setIsYouTubeInitiator(false);
      setMainVideoType('youtube');
      setFocusedPeerId(null);
      setShowControls(false);
    });

    socket.on('youtube-pip', () => {
      debugLog('Received youtube-pip event');
      setIsYouTubeMainVideo(false);
      setMainVideoType(null);
      setShowControls(true);
    });

    socket.on('youtube-ready', ({ videoId }) => {
      debugLog('Received youtube-ready event', videoId);
      if (videoId === sharedVideoId) {
        setIsYouTubeMainVideo(true);
        setMainVideoType('youtube');
        setFocusedPeerId(null);
        setShowControls(false);
        // Request sync from other peers
        socket.emit('youtube-sync-request', { roomId: DEFAULT_ROOM_ID });
      }
    });

    socket.on('youtube-control', (data) => {
      debugLog('Received youtube-control event', data);
      if (youtubePlayerRef.current && youtubePlayerRef.current.handleYouTubeControl) {
        youtubePlayerRef.current.handleYouTubeControl(data);
      } else {
        console.error('YouTube player ref or handleYouTubeControl not available', youtubePlayerRef.current);
      }
    });

    socket.on('youtube-sync-request', () => {
      if (youtubePlayerRef.current && youtubePlayerRef.current.syncPlayback) {
        youtubePlayerRef.current.syncPlayback();
      }
    });

    socket.on('youtube-sync-response', (data) => {
      if (youtubePlayerRef.current && youtubePlayerRef.current.handleYouTubeControl) {
        youtubePlayerRef.current.handleYouTubeControl(data);
      } else {
        console.error('YouTube player ref or handleYouTubeControl not available', youtubePlayerRef.current);
      }
    });

    return () => {
      socket.off('share-youtube');
      socket.off('youtube-main-video');
      socket.off('youtube-pip');
      socket.off('youtube-ready');
      socket.off('youtube-control');
      socket.off('youtube-sync-request');
      socket.off('youtube-sync-response');
    };
  }, [sharedVideoId]);

  const requestYouTubeSync = () => {
    socket.emit('youtube-sync-request', { roomId: DEFAULT_ROOM_ID });
  };

  const renderPIPVideos = () => {
    const pipVideos = [];

    // Always add local video PIP
    if (localStream) {
      pipVideos.push(
        <video
          key="local-video"
          ref={handleLocalVideoRef}
          autoPlay
          playsInline
          muted
          className={`video-item local-video pip ${focusedPeerId === 'local' ? 'focused' : ''}`}
          onClick={() => handlePipClick('local')}
        />
      );
    }

    // Always add peer videos PIP
    Object.entries(peers).forEach(([peerId, { stream }]) => {
      if (stream) {
        pipVideos.push(
          <video
            key={`pip-${peerId}`}
            autoPlay
            playsInline
            className={`video-item remote-video pip ${focusedPeerId === peerId ? 'focused' : ''}`}
            ref={(el) => {
              if (el) {
                el.srcObject = stream;
              }
            }}
            onClick={() => handlePipClick(peerId)}
          />
        );
      }
    });

    // Add YouTube PIP if it exists
    if (sharedVideoId) {
      pipVideos.push(
        <YouTubePIP 
          key={`youtube-pip-${sharedVideoId}`} 
          videoId={sharedVideoId} 
          onClick={handleYouTubePIPClick}
          isFocused={isYouTubeMainVideo}
        />
      );
    }

    return pipVideos;
  };

  const renderMainVideo = () => {
    if (isYouTubeMainVideo && sharedVideoId) {
      return (
        <div className="youtube-wrapper" style={{ width: '100%', height: '100%' }}>
          <YouTubePlayer 
            key={`youtube-${sharedVideoId}-${isYouTubeMainVideo}`}
            videoId={sharedVideoId} 
            roomId={DEFAULT_ROOM_ID} 
            isInitiator={isYouTubeInitiator} 
            ref={youtubePlayerRef}
          />
          <div className="youtube-controls">
            <button onClick={() => youtubePlayerRef.current?.handlePlay()}>Play</button>
            <button onClick={() => youtubePlayerRef.current?.handlePause()}>Pause</button>
            <input 
              type="range" 
              min="0" 
              max="100" 
              onChange={(e) => youtubePlayerRef.current?.handleSeek(Number(e.target.value))} 
            />
            <button onClick={requestYouTubeSync}>Sync</button>
          </div>
        </div>
      );
    } else if (focusedPeerId) {
      const stream = focusedPeerId === 'local' ? localStream : peers[focusedPeerId]?.stream;
      if (stream) {
        return (
          <video
            key={`main-${focusedPeerId}`}
            ref={mainVideoRef}
            autoPlay
            playsInline
            muted={focusedPeerId === 'local'}
            className={`video-item ${focusedPeerId === 'local' ? 'local-video' : 'remote-video'} focused`}
          />
        );
      }
    }
    return null;
  };

  const handleLocalVideoRef = (el: HTMLVideoElement | null) => {
    if (el) {
      localVideoRef.current = el;
      if (!isLocalVideoReady) {
        setIsLocalVideoReady(true);
        log('Local video element is ready');
      }
      setLocalVideoStream();
    }
  };

  const setLocalVideoStream = useCallback(() => {
    if (localStream && localVideoRef.current) {
      try {
        localVideoRef.current.srcObject = localStream;
        log('Local video srcObject set successfully');
      } catch (error) {
        console.error('Error setting local video srcObject:', error);
      }
    }
  }, [localStream]);

  useEffect(() => {
    return () => {
      log('Cleaning up Video component');
      Object.values(peersRef.current).forEach(({ peer }) => peer.destroy());
      socket.off('new-participant');
      socket.off('signal');
      socket.off('peer-disconnected');
      if (localStream) {
        localStream.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    if (mainVideoRef.current) {
      if (focusedPeerId === 'local' && localStream) {
        mainVideoRef.current.srcObject = localStream;
      } else if (focusedPeerId && focusedPeerId !== 'local' && peers[focusedPeerId]?.stream) {
        mainVideoRef.current.srcObject = peers[focusedPeerId].stream;
      }
    }
  }, [focusedPeerId, localStream, peers]);

  const handleStreamUpdate = useCallback((newStream: MediaStream) => {
    setLocalStream(newStream);
    Object.values(peersRef.current).forEach(({ peer }) => {
      peer.removeStream(localStream!);
      peer.addStream(newStream);
    });
  }, [localStream]);

  const toggleOverlay = (overlay: 'chat' | 'settings' | 'mediaShare') => {
    setShowChat(prev => overlay === 'chat' ? !prev : false);
    setShowSettings(prev => overlay === 'settings' ? !prev : false);
    setShowMediaShare(prev => overlay === 'mediaShare' ? !prev : false);
  };

  if (!isSetupComplete) {
    return <SetupSequence onComplete={initializeRoom} />;
  }

  return (
    <div ref={containerRef} className="video-room">
      {isWaiting ? (
        <div className="waiting-screen">
          <h2>Waiting for other participants to join...</h2>
          <div className="loading-animation"></div>
        </div>
      ) : (
        <div className="video-container">
          <div className="main-video">
            {renderMainVideo()}
          </div>
          <div 
            ref={pipRef}
            className="pip-container"
            style={{
              position: 'absolute',
              top: `${pipPosition.y}px`,
              left: `${pipPosition.x}px`,
              touchAction: 'none',
            }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {renderPIPVideos()}
          </div>
        </div>
      )}
      <div className={`controls ${showControls ? '' : 'hidden'}`}>
        <button className="icon-button" onClick={() => toggleOverlay('settings')}>
          <FaCog />
        </button>
        <button className="icon-button" onClick={toggleAudio}>
          {isAudioMuted ? <FaMicrophoneSlash /> : <FaMicrophone />}
        </button>
        <button className="icon-button" onClick={() => toggleOverlay('chat')}>
          <FaComments />
        </button>
        <button className="icon-button" onClick={() => toggleOverlay('mediaShare')}>
          <FaShareAlt />
        </button>
      </div>
      {showChat && (
        <div className="overlay chat-overlay show">
          <button className="close-button" onClick={() => setShowChat(false)}>×</button>
          <Chat 
            roomId={DEFAULT_ROOM_ID} 
            messages={chatMessages} 
            sendMessage={sendChatMessage}
          />
        </div>
      )}
      {showSettings && (
        <div className="overlay settings-overlay show">
          <button className="close-button" onClick={() => setShowSettings(false)}>×</button>
          <DeviceSettings localStream={localStream} onStreamUpdate={handleStreamUpdate} />
        </div>
      )}
      {showMediaShare && (
        <div className="overlay media-share-overlay show">
          <button className="close-button" onClick={() => setShowMediaShare(false)}>×</button>
          <MediaShare onMediaShare={handleMediaShare} />
        </div>
      )}
    </div>
  );
};

export default Video;