import React, { useState } from 'react';
import { extractYouTubeVideoId } from '../utils/videoUtils';

interface MediaShareProps {
  onMediaShare: (videoId: string, type: 'youtube' | 'twitter') => void;
}

const MediaShare: React.FC<MediaShareProps> = ({ onMediaShare }) => {
  const [url, setUrl] = useState('');
  const [isValid, setIsValid] = useState<boolean | null>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const youtubeVideoId = extractYouTubeVideoId(url);
    if (youtubeVideoId) {
      setIsValid(true);
      onMediaShare(youtubeVideoId, 'youtube');
    } else {
      // For now, we'll only handle YouTube. You can add Twitter handling later.
      setIsValid(false);
    }
  };

  return (
    <div className="media-share">
      <h2>Share YouTube Video</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter YouTube URL"
        />
        <button type="submit">Share</button>
      </form>
      {isValid === false && <p className="error">Invalid URL. Please enter a valid YouTube URL.</p>}
    </div>
  );
};

export default MediaShare;