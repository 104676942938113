import React, { useState, useEffect, useRef } from 'react';
import { getLocalStream } from '../services/webrtc';

interface DeviceSettingsProps {
  localStream: MediaStream | null;
  onStreamUpdate: (stream: MediaStream) => void;
}

const DeviceSettings: React.FC<DeviceSettingsProps> = ({ localStream, onStreamUpdate }) => {
  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState<string>('');
  const [selectedVideoDevice, setSelectedVideoDevice] = useState<string>('');

  useEffect(() => {
    const initializeDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        setAudioDevices(devices.filter(device => device.kind === 'audioinput'));
        setVideoDevices(devices.filter(device => device.kind === 'videoinput'));
        
        if (localStream) {
          const audioTrack = localStream.getAudioTracks()[0];
          const videoTrack = localStream.getVideoTracks()[0];
          setSelectedAudioDevice(audioTrack?.getSettings().deviceId || '');
          setSelectedVideoDevice(videoTrack?.getSettings().deviceId || '');
        }
      } catch (error) {
        console.error('Error initializing devices:', error);
      }
    };

    initializeDevices();
  }, [localStream]);

  const updateStream = async () => {
    try {
      const newStream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: selectedAudioDevice },
        video: { deviceId: selectedVideoDevice }
      });
      onStreamUpdate(newStream);
    } catch (error) {
      console.error('Error updating stream:', error);
    }
  };

  const handleDeviceChange = (event: React.ChangeEvent<HTMLSelectElement>, type: 'audio' | 'video') => {
    const deviceId = event.target.value;
    if (type === 'audio') {
      setSelectedAudioDevice(deviceId);
    } else {
      setSelectedVideoDevice(deviceId);
    }
  };

  return (
    <div className="device-settings">
      <h2>Device Settings</h2>
      <div>
        <label htmlFor="audioDevice">Microphone:</label>
        <select
          id="audioDevice"
          value={selectedAudioDevice}
          onChange={(e) => handleDeviceChange(e, 'audio')}
        >
          {audioDevices.map(device => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label || `Microphone ${device.deviceId.slice(0, 5)}`}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="videoDevice">Camera:</label>
        <select
          id="videoDevice"
          value={selectedVideoDevice}
          onChange={(e) => handleDeviceChange(e, 'video')}
        >
          {videoDevices.map(device => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label || `Camera ${device.deviceId.slice(0, 5)}`}
            </option>
          ))}
        </select>
      </div>
      <button onClick={updateStream}>Apply Changes</button>
    </div>
  );
};

export default DeviceSettings;